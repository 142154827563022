<template>
    <div>
      <v-overlay :value="overlay">
        <v-progress-circular indeterminate size="64"></v-progress-circular>
      </v-overlay>
  
      <v-card>
        <v-toolbar flat color="white" class="mt-3">
          <v-toolbar-title>Descargar</v-toolbar-title>
          <v-divider class="mx-4" inset vertical> </v-divider>
          <v-spacer></v-spacer>
  
          <v-menu v-model="menu" :close-on-content-click="false"  >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="rangoFechas"
                label="Fechas"
                persistent-hint
                readonly
                append-icon="mdi-calendar-multiple"
                v-bind="attrs"
                v-on="on"
                :clearable="true"
                
                
              ></v-text-field>
            </template>
            <v-date-picker
              range
              v-model="searchFechas"
              no-title
              @change="menu = false; cargarReporte()"
              
              locale="es-es"
            >
            </v-date-picker>
          </v-menu>
  
          <v-autocomplete
            clearable
            :items="listaProveedores"
            label="Proveedores"
            dense
            item-text="proveedor_nombres"
            item-value="party_id"
            v-model="searchProv"
            append-icon="mdi-magnify"
            class="mt-1 mx-4"
            return-object
            @change="cargarReporte()"
            flat
          ></v-autocomplete>
        </v-toolbar>
        <v-toolbar flat color="white">
          <v-btn
            color="primary"
            small
            class="ma-2 white--text"
            @click="exportar()"
          >
            <v-icon left>mdi-microsoft-excel</v-icon>
            Exportar
          </v-btn>
        </v-toolbar>
  
        <v-data-table
          :headers="headersLista"
          :items="lista"          
          :loading="loadingTable"
          :items-per-page="25"
          :options.sync="options"
          loading-text="Cargando datos..."
          no-results-text="No se han encontrado datos"
          no-data-text="Sin datos"
          class="elevation-1"
          dense
        >
        </v-data-table>
      </v-card>
    </div>
  </template>
  
  <script>
  import { mapState, mapMutations, mapActions, mapGetters } from "vuex";
  
  export default {
    name: "ReporteComprasProveedor",
    //invoice_id	
    //codigo_establecimiento	
    //codigo_punto_emision	
    //nunm_fa	
    //fecha_emision	
    //fecha_venc	
    //total	
    //pagado	
    //fecha_pago	
    //saldo	
    //proveedor

    data: () => ({
      headersLista: [
        { text: "Proveedor" , value: "proveedor"},
        { text: "Código Fa", value: "invoice_id" },
        { text: "Num. Fa", value: "num_fa" },
        { text: "Fecha. Emi", value: "fecha_emision" },
        { text: "Fecha. Ven", value: "fecha_venc" },
        { text: "Total factura", value: "total" },
        { text: "Pagado", value: "pagado" },
        { text: "Fecha de pago", value: "fecha_pago" },
        { text: "Saldo", value: "saldo" },
        
      ],
      search: "",
  
      listaProveedores: [],
      lista: [],
      overlay: false,
      options: {},
      dialog: false,
      notifications: false,
      
      widgets: true,
      searchFechas: [],
      searchProv: "",
      menu: false,
      filters: [
        {
          cols: 6,
          class: "py-0 py-md-2 col-md-2 pr-0 pr-sm-2",
          v_model: [],
          label: "Fecha",
          type: "input_date",
          clearable: true,
        },
        {
          cols: 6,
          class: "py-0 py-md-2 col-md-2 pl-0 pl-sm-2",
          v_model: "",
          label: "Bodega",
          type: "input_select",
          text: "tipoNombre",
          value: "tipoId",
          clearable: true,
          multiple: true,
          items: [],
        },
      ],
    }),
    computed: {
      ...mapState("master", ["loadingTable", "user", "tenantId"]),
      //...mapGetters("access", ["btnAbrirCaja"]),
      rangoFechas() {
        return this.searchFechas.join(" ~ ");
      },
    },
    methods: {
      ...mapMutations("master", [
        "setUrl",
        "setMenu",
        "setTitleToolbar",
        "setLoadingTable",
      ]),
  
      ...mapActions("master", ["requestApi", "alertNotification"]),
  
      exportar() {
        this.overlay = true;
  
        if (this.searchFechas[0] == null) {
          this.overlay = false;
          //alert('Seleccione una fecha');
          this.alertNotification({
            param: {
              html: "Seleccione una fecha.",
              timer: 10000,
              title: "Error",
              icon: "mdi-cancel",
              confirmButtonColor: "red",
            },
          });
  
          return false;
        }
  
        if (this.searchProv == null) {
          this.overlay = false;
          //alert('Seleccione un establecimiento');
          this.alertNotification({
            param: {
              html: "Seleccione un proveedor.",
              timer: 10000,
              title: "Error",
              icon: "mdi-cancel",
              confirmButtonColor: "red",
            },
          });
          return false;
        }
  
        this.setUrl("reportes");
        this.requestApi({
          method: "POST",
          data: {
            accion: "exportarComprasProveedor",
            desde: this.searchFechas[0],
            hasta: this.searchFechas[1],
            proveedor: this.searchProv.party_id,
          },
        })
          .then((res) => {
            //console.log(res.data);
  
            var a = document.createElement("a");
            a.href =
              "data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64," +
              res.data.detail.datos;
            a.download = res.data.detail.archivo;
            a.click();
            this.overlay = false;
          })
          .then(() => {});
      },
  
      cargarReporte() {
        //console.log(this.searchFechas);
      
        this.setUrl("reportes");
        this.requestApi({
          method: "POST",
          data: {
            accion: "comprasProveedor",
            desde: this.searchFechas[0],
            hasta: this.searchFechas[1],
            proveedor: this.searchProv.party_id,
          },
        })
          .then((res) => {
            //console.log(res.data.detail);
            this.lista = res.data.detail.proveedores;
          })
          .then(() => {})
          .catch(() =>{});
      },
  
      cargarProveedores() {
        this.setUrl("reportes");
        this.requestApi({
          method: "POST",
          data: {
            accion: "datosComprasProveedor"
          },
        })
          .then((res) => {
            this.listaProveedores  = res.data.detail.proveedores;
          })
          .then(() => {});
      },
    },
  
    mounted() {
      this.cargarProveedores();
      this.cargarReporte();
      this.setTitleToolbar("COMPRAS PROVEEDOR");
    },
  
    watch: {},
  };
  </script>
  